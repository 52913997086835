
import { materialCells } from '@jsonforms/material-renderers'
import { JsonForms } from '@jsonforms/react'
import { Box, styled } from '@mui/material';
import useAIBoostLeasing from 'hooks/useAIBoostLeasing';
import Ajv from 'ajv';
import ajvErrors from 'ajv-errors';
import { useParams } from 'react-router';
import Spinner from 'components/Spinner';
import useLocales from 'hooks/useLocales';
import { useUpdateContractCustomFieldsMutation } from 'redux/api/contractApi';
import { LoadingButton } from '@mui/lab';
import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
type Props = {
  contractType: string;
};
type KeyValue = {
  uuid: string;
  value: string;
};
const ajv = new Ajv({ allErrors: true });
ajvErrors(ajv);

const ContractAiBoostLeasing = ({ contractType }: Props) => {
  const { id } = useParams();
  const { translate } = useLocales();
  const { enqueueSnackbar } = useSnackbar();
  const { data, schema, UISchema, renderers, setData, isLoading, isSuccess } = useAIBoostLeasing({
    contractId: id!,
    contractType,
  });

  const [updateContract, { isError }] = useUpdateContractCustomFieldsMutation();
  const [updatingCustomFields, setupdatingCustomFields] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(!isSuccess);
    return () => {
      setLoading(true);
    };
  }, [isSuccess]);

  if (isLoading) {
    return (
      <Box
        component={'div'}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignContent: 'center',
          padding: '3rem',
          width: '100%',
        }}
      >
        {/* <CircularProgress color="primary" size={60} /> */}
        <Spinner />
      </Box>
    );
  }

  const updateCustomFields = async () => {
    const customFieldsArray: KeyValue[] = [];
    if (data && Object.keys(data).length > 0) {
      Object.entries(data).forEach(([key, value]) => {
        const typedValue = value as {
          answer: string;
          uuid: string;
        };
        customFieldsArray.push({ uuid: typedValue.uuid, value: typedValue.answer });
      });
    }

    try {
      setupdatingCustomFields(true);
      const response = await updateContract({
        contractId: id!,
        payload: { data: customFieldsArray },
      });

      if (Object.keys(response)?.length) {
        enqueueSnackbar(translate('contract.updated', 'Custom fields successfully updated.'));
      }
    } catch {
      enqueueSnackbar(translate('error.serverError', 'Something went wrong!'), {
        variant: 'error',
      });
    } finally {
      setupdatingCustomFields(false);
    }
  };
  return (
    <ContractAPBoostDiv>
      {schema && UISchema && (
        <JsonForms
          schema={schema}
          uischema={UISchema}
          data={data}
          renderers={renderers}
          cells={materialCells}
          ajv={ajv}
          onChange={({ errors, data }) => setData(data)}
          //   readonly={readonly ? true : false}
        />
      )}

      {!loading ? (
        data && Object.keys(data)?.length ? (
          <Box display="flex" justifyContent="flex-end">
            <LoadingButton
              loading={updatingCustomFields}
              variant="contained"
              onClick={updateCustomFields}
              autoFocus
            >
              {' '}
              {translate('common.save', 'Save')}{' '}
            </LoadingButton>
          </Box>
        ) : (
          <div>{translate('common.noData', 'No data is available')}</div>
        )
      ) : null}
    </ContractAPBoostDiv>
  );
};

export default ContractAiBoostLeasing

const ContractAPBoostDiv = styled("div")(() => ({
    width: "100%",
    paddingLeft: "1rem",
    paddingTop: "1rem",
    "div>div>.MuiGrid-container.MuiGrid-root": {
        display: "grid",
        gridTemplateColumns: "2fr 1fr 50px 50px",
        alignItems: "center",
    }
}))