import React, { useState, useEffect, Dispatch, SetStateAction } from 'react';

import {
  Card,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  TableCell,
  Checkbox,
  checkboxClasses,
  TablePagination,
  CardContent,
  Box,
  LinearProgress,
  CircularProgress,
  styled,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import CustomTableHeaders from './custom-table-headers';
import Popup from '../popup/popup';

import SelectedRowsHeader from '../../pages/company/selected-rows-header';
import _get from 'lodash/get';
import Spinner from '../../components/Spinner';
import { useNavigate, useLocation } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import { Opacity } from '@mui/icons-material';

type Header = {
  text: string;
  sortable?: boolean;
};
type SelectedType = string[];
type Props = {
  headers: Header[];
  filterName?: string;
  onFilterName?: (value: string) => void;
  searchBarText?: string;
  SingleRow: React.ElementType;
  allowMultiSelect: boolean;
  fetchingURL?: string;
  rowsData?: any[];
  handleDelete?: (ids: string) => void;
  handleRestore?: (ids: string) => void;
  fetchAgain?: (value: string, rowsPerPage?: number) => void;
  totalRecords?: number;
  handleUpdateTrue?: () => void;
  isDisableDelete?: boolean;
  isDisableAccess?: boolean;
  enableRestore?: boolean;
  deleteConfirmationMsg?: string;
  isLoading?: boolean;
  openCloseModal?: (value: boolean) => void;
  setSelectedIds?: (value: any) => void;
  scrollPaginate?: () => Promise<void>;
  noDataMsg?: string;
  selected?: string[];
  setSelected?: Dispatch<SetStateAction<SelectedType>>;
  paginate?: boolean;
  stickyHeader?: boolean;
  isFooterLoading?: boolean;
  driveTable?: boolean;
  maxHeight?: string;
};
let callPaginate = false;
let prevScrollTop = 0;

export default function CustomTable(props: Props) {
  // Inside your component
  const navigate = useNavigate();
  const location = useLocation();
  const [rows, setRows] = useState<any[]>();
  const theme = useTheme();
  const {
    headers,

    SingleRow,
    allowMultiSelect,

    rowsData,
    handleDelete,
    handleRestore,
    scrollPaginate,

    totalRecords,
    handleUpdateTrue,
    isDisableDelete,
    isDisableAccess,
    enableRestore,
    deleteConfirmationMsg,
    isLoading,
    openCloseModal,
    setSelectedIds,
    noDataMsg,
    selected,
    setSelected,
    paginate = true,
    isFooterLoading,
    maxHeight,
    stickyHeader,
    driveTable,
  } = props;
  useEffect(() => {
    if (rowsData) {
      setRows(rowsData);
    }
  }, [rowsData]);

  const [orderBy, setOrderBy] = useState<string>();
  const [direction, setDirection] = useState<'asc' | 'desc'>('asc');

  const [page, setPage] = useState(0);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const pageParam = searchParams.get('page');
    const rowsPerPageParam = searchParams.get('rowsPerPage');

    if (pageParam) {
      setPage(parseInt(pageParam) - 1);
    }
    if (rowsPerPageParam) {
      setRowsPerPage(parseInt(rowsPerPageParam));
    }
  }, [location.search]);

  const sortHandler = (column: string, sortDirection: 'asc' | 'desc') => {
    if (column === orderBy) {
      setDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setDirection('asc');
      setOrderBy(column);
      // Call sorting function
    }
  };

  const selectAllHandler = () => {
    // @ts-ignore-next-line
    let selectedContract: any[] = [];

    rows &&
      rows.map((row) => {
        if (_get(row, 'permission.permissionName') === 'Editor')
          selectedContract.push(_get(row, 'id'));
      });
    if (setSelected) {
      setSelected(selectedContract);
    }

    if (rows && selected && selected.length) {
      if (setSelected) {
        setSelected([]);
      }
    }
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);

    // Update the page query parameter
    const searchParams = new URLSearchParams(location.search);
    searchParams.set('page', String(newPage + 1));
    navigate({
      pathname: location.pathname,
      search: searchParams.toString(),
    });

    // if (fetchAgain) {
    //   fetchAgain(String(newPage + 1), rowsPerPage);
    // }
  };

  const INITIAL_ROWS_PER_PAGE = 10;

  const [rowsPerPage, setRowsPerPage] = useState(INITIAL_ROWS_PER_PAGE);

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    const searchParams = new URLSearchParams(location.search);
    searchParams.set('rowsPerPage', String(parseInt(event.target.value, 10)));
    searchParams.set('page', String(1));
    navigate({
      pathname: location.pathname,
      search: searchParams.toString(),
    });
    // if (fetchAgain) {
    //   fetchAgain(String(1), parseInt(event.target.value, 10));
    // }
  };

  const selectRowHandler = (id: any) => {
    // @ts-ignore
    if (selected && selected.includes(id)) {
      // @ts-ignore
      setSelected(selected.filter((item) => item !== id));
    } else {
      // @ts-ignore
      setSelected((prev) => [id, ...prev]);
    }
  };

  const [open, setOpen] = useState<boolean>(false);

  const handlePopupClose = () => {
    setOpen(false);
  };

  const deleteRows = (ids: string) => {
    if (handleDelete) {
      if (setSelected) {
        setSelected([]);
      }
      handleDelete(ids);
    }
  };

  const restoreRows = (ids: string) => {
    if (handleRestore) {
      if (setSelected) {
        setSelected([]);
      }
      handleRestore(ids);
    }
  };

  const handleScroll = async (e: any) => {
    const { scrollTop, clientHeight, scrollHeight } = e.target;

    // Check if the user is scrolling down
    if (scrollTop > prevScrollTop) {
      const callPosition = clientHeight + scrollTop + 200;

      if (callPosition >= scrollHeight && !callPaginate && scrollPaginate) {
        callPaginate = true;

        await scrollPaginate();

        callPaginate = false;
      }
    }
    // Update the previous scroll position
    prevScrollTop = scrollTop;
  };

  if (!rows || !!isLoading) {
    return (
      <Box
        component={'div'}
        sx={{ display: 'flex', justifyContent: 'center', alignContent: 'center' }}
      >
        {/* <CircularProgress color="primary" size={60} /> */}
        <Spinner />
      </Box>
    );
  }

  let content = null;
  if (rows.length) {
    content = rows?.map((row) => {
      // row.permission.permissionName = "Viewer"
      // @ts-ignore
      const isChecked = selected && selected.includes(row.id);

      return (
        <TableRow key={row.id} hover={true}>
          {allowMultiSelect && (
            <TableCell>
              <Checkbox
                checked={isChecked}
                onChange={() => selectRowHandler(row.id)}
                disabled={row?.permission?.permissionName !== 'Editor'}
                sx={{
                  [`&, &.${checkboxClasses.checked}`]: {
                    color: theme.customPalette?.tableHeadColor ?? '',
                  },
                }}
              />
            </TableCell>
          )}
          <SingleRow
            {...row}
            driveTable={driveTable}
            key={row.id}
            handleDelete={handleDelete}
            handleRestore={handleRestore}
            handleUpdateTrue={handleUpdateTrue}
            selectedRowsLength={selected?.length}
            setSelected={setSelected}
          />
        </TableRow>
      );
    });
  } else if (!isFooterLoading && !isLoading && !rows?.length && noDataMsg) {
    content = (
      <TableRow>
        <TableCell colSpan={8}>
          <NoDataDiv>{noDataMsg}</NoDataDiv>
        </TableCell>
      </TableRow>
    );
  }



  return (
    <Card>
      <CardContent>
        {selected && selected.length ? (
          <SelectedRowsHeader
            numSelected={selected}
            rowsCount={rows?.length || 0}
            handleDelete={deleteRows}
            handleRestore={restoreRows}
            handleSelection={selectAllHandler}
            isDisableDelete={isDisableDelete}
            isDisableAccess={isDisableAccess}
            enableRestore={enableRestore}
            deleteConfirmationMsg={deleteConfirmationMsg}
            openCloseModal={openCloseModal}
            setSelectedIds={setSelectedIds}
          />
        ) : null}
        <TableContainer
          onScroll={handleScroll}
          sx={{ minHeight: 100, maxHeight: maxHeight ? maxHeight : 'unset' }}
        >
          <Table stickyHeader={stickyHeader ? true : false} size="small" aria-label="sticky table">
            {!(selected?.length ?? 0) && (
              <CustomTableHeaders
                headers={headers}
                allowMultiSelect={allowMultiSelect}
                direction={direction}
                sortHandler={sortHandler}
                orderBy={orderBy}
                selectAllHandler={selectAllHandler}
              />
            )}

            <TableBody>
              {content}
            </TableBody>
          </Table>
        </TableContainer>
        {isFooterLoading && callPaginate && (
          <Box
            sx={{ backgroundColor: "#fff", pb: "1rem", display: 'flex', position: "absolute", bottom: 0, left: 0, width: "100%", justifyContent: "center", alignItems: "center" }}
          >
            <CircularProgress size={"1.5rem"} color='info' sx={{ mr: 1 }} />
            Loading.
          </Box>
        )}

        {paginate && (
          <TablePagination
            component="div"
            count={totalRecords || 0}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[10, 25]}
          />
        )}
      </CardContent>
      <Popup
        open={open}
        onClose={handlePopupClose}
        title="Delete organizations?"
        deleteFunction={() => { }}
      />
    </Card>
  );
}

const NoDataDiv = styled('div')(({ theme }) => ({
  color: '#637381',
  textAlign: 'center',
  animationName: "NoDataDiv",
  animationDuration: "2s",
  "@keyframes NoDataDiv": {
    "0%": { opacity: 0 },
    "99%": { opacity: 0 },
    "100%": { opacity: 1 }
  }
}))