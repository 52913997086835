import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { IPartyItem, IPartyListDetails, IPartyListQuery } from "../../@types/party";
import { ISuggestedParty } from "pages/contracts/contract-types";
import { axiosBaseQuery } from "redux/axiosBaseQuery";

type IPartyType = {
    id: string;
    type: string;
};
export const partyAPI = createApi({
    reducerPath: "API/PARTY",
    baseQuery: axiosBaseQuery(),
    tagTypes: ['partyRole', "parties"],
    endpoints(builder) {
        return {
            fetchPartyRoleSuggestions: builder.query<IPartyType[], void>({
                query() {

                    let endpointURL = `/cs-api/partyRoles/suggestion`;

                    return {
                        url: endpointURL
                    }
                },
                transformResponse(response: any) {
                    return response.content
                },
                providesTags: ["partyRole"],
                keepUnusedDataFor: 60 * 10 // 10Min keep cache
            }),
            fetchParties: builder.query<ISuggestedParty[], string>({
                query(organizationUUID) {

                    let endpointURL = `cs-api/parties/suggestion/${organizationUUID}`;

                    return {
                        url: endpointURL
                    }
                },
                transformResponse(response: any) {
                    return response.content
                },
                providesTags: ["parties"],
                keepUnusedDataFor: 60 * 10 // 10Min keep cache
            }),
            fetchPartiesList: builder.query<IPartyListDetails, IPartyListQuery>({
                query(params) {
                    // partyRoleId=1 (Own organization)
                    // partyRoleId=2 (Counter party)
                    let endpointURL = `cs-api/parties`;

                    return {
                        url: endpointURL,
                        params: params
                    }
                },
                transformResponse(response: any) {
                    return response
                },
                providesTags: ["parties"],
            }),
            fetchPartyByUUID: builder.query<IPartyItem, string>({
                query(uuid) {
                    let endpointURL = `cs-api/parties/${uuid}`;

                    return {
                        url: endpointURL
                    }
                },
                transformResponse(response: any) {
                    return response.content
                },
                providesTags: ["parties"],
            }),
            updatePartyByUUID: builder.mutation<string, { uuid: string, data: IPartyItem }>({
                query({ uuid, data }) {
                    let endpointURL = `cs-api/parties/${uuid}`;

                    return {
                        url: endpointURL,
                        data: data,
                        method: "PUT"
                    }
                },
                invalidatesTags: ["parties"],
            }),

        }
    }
})

export const { useFetchPartyRoleSuggestionsQuery, useFetchPartiesQuery, useFetchPartiesListQuery, useFetchPartyByUUIDQuery, useUpdatePartyByUUIDMutation } = partyAPI