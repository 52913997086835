import { InputLabel, MenuItem, Select, FormControl } from '@mui/material';
import { Field } from 'formik';

type Props = {
    label: string;
    name: string;
    options: { label: string | number; value: string | number }[];
    isLoading?: boolean;
    disabled?: boolean;
    onChange?: (event: any) => void,
    value?: string | number
};

const FMSelectInput = ({ label, name, options, isLoading, disabled, onChange: handleChange, value }: Props) => {
    console.log();

    return (
        <Field name={name}>
            {({ field, meta, form }: any) => (
                <FormControl fullWidth>
                    <InputLabel id={`${name}`}>{label}</InputLabel>
                    <Select
                        name={field.name}
                        value={value || field.value || ""}
                        onChange={(event) => {
                            if (handleChange) {
                                handleChange(event)
                            }
                            form.setFieldValue(name, event.target.value)
                        }}
                        onBlur={field.onBlur}
                        labelId={`${name}-label`}
                        label={label}
                        disabled={isLoading || disabled}
                        error={meta.touched && Boolean(meta.error)}
                    >
                        {options.map((e) => (
                            <MenuItem key={e.value} value={e.value}>
                                {e.label}
                            </MenuItem>
                        ))}
                    </Select>
                    {meta.touched && meta.error ? (
                        <div style={{ color: 'red' }}>{meta.error}</div>
                    ) : null}
                </FormControl>
            )}
        </Field>
    );
};

export default FMSelectInput;
