import { Container } from '@mui/material'
import HeaderBreadcrumbs from 'components/HeaderBreadcrumbs'
import Page from 'components/Page'
import EditParies from 'components/pagewise/parties/EditParies'
import useLocales from 'hooks/useLocales'
import React from 'react'
import { useParams } from 'react-router'
import { useFetchPartyByUUIDQuery } from 'redux/api/partyAPI'

type Props = {}

const EditPartiesPage = (props: Props) => {
    const { translate } = useLocales()
    const { id } = useParams()
    const { data, isLoading: isLoadingData, isFetching } = useFetchPartyByUUIDQuery(id!, { skip: !id })
    const isLoading = isLoadingData || isFetching
    return <Page title={translate('parties.edit', 'Edit parties')}>
        <Container maxWidth={false}>
            <HeaderBreadcrumbs heading={translate('parties.edit', 'Edit parties')} links={[]} />
            <EditParies data={data} isLoading={isLoading} />
        </Container>
    </Page>
}

export default EditPartiesPage